import React, { ReactNode, useState } from 'react';
import { useController, Control } from 'react-hook-form';
import * as InputTheme from './Theme';
import { Controls } from 'components';

interface InputControllerProps {
    name: string;
    control: Control<any>;
    label?: string;
    defaultValue?: string;
    contents?: ReactNode;
    isAvatar?: boolean;
    onChange?: (file: File | null) => void;
    trigger?: (name?: string | string[]) => Promise<boolean>;
}

export const InputPdfController: React.FC<
    InputControllerProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
    label,
    control,
    defaultValue,
    name,
    isAvatar = false, // Default to false for generic file uploads
    trigger,
    contents,
    onChange,
    ...inputProps
}) => {
    const { field, fieldState } = useController<any>({
        control,
        name,
        defaultValue,
    });

    const [fileName, setFileName] = useState<string | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            field.onChange(file);
            if (onChange) {
                onChange(file);
            }
            setFileName(file.name);
            trigger && trigger?.(name);
        }
    };

    return (
        <InputTheme.AvatarWrapper $isAvatar={isAvatar}>
            <InputTheme.Body>
                <InputTheme.UploaderWrapper>
                    <input
                        type="file"
                        id={`input-${name}`} // Unique ID for each input
                        hidden
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        {...field}
                        value="" // Prevent React from controlling the input value
                        onChange={handleFileChange}
                        {...inputProps}
                    />
                    <label
                        htmlFor={`input-${name}`} // Match the label to the input ID
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <InputTheme.UploadIcon src="/assets/icons/upload-icon.png" alt="upload" />
                        {contents}
                    </label>
                </InputTheme.UploaderWrapper>
                {/* Display file name */}
                <InputTheme.FileName>
                    {fileName ? `Uploaded: ${fileName}` : 'No file selected'}
                </InputTheme.FileName>
                <Controls.Hint>{fieldState?.error?.message}</Controls.Hint>
            </InputTheme.Body>
        </InputTheme.AvatarWrapper>
    );
};

import { useForm } from 'react-hook-form';
import * as Theme from './Theme';
import { useLocales } from 'hooks/locales';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { AuthBanner, CheckBoxes } from 'components';
import { useRegisterSchema } from 'schemas';
import { setModalContent } from 'store/controlsSlice';
import { router } from 'routers';
import { useSignUp } from 'services/hooks';
import { FormRow } from 'components/modal-windows/FormRow';

export const SignUp = () => {
    const dispatch = useDispatch<any>();
    const [stage, setStage] = useState(1);
    const { isRTL } = useSelector<any>((states) => states?.locales) as any;
    const registerSchema = useRegisterSchema();
    const {
        control,
        formState: { isValid },
        handleSubmit,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(registerSchema),
    });
    const { trans } = useLocales();

    const {
        mutate: signUp,
        isPending: isLoading,
        error,
    } = useSignUp({
        onSuccess: () => {
            dispatch(
                setModalContent({
                    modalContent: {
                        type: 'info',
                        title: 'Info',
                        subtitle: trans('signup.success.message'),
                    },
                }),
            );

            router.navigate('home', {}, { replace: true });
        },
    });

    const handleRegister = (data: any) => {
        signUp({
            ...data,
        });
    };

    const toggleStages = () => {
        setStage((prev) => (prev === 1 ? 2 : 1));
    };

    return (
        <Theme.Body onSubmit={handleSubmit(handleRegister)}>
            <AuthBanner />

            <Theme.FormSection>
                <Theme.FormSlider stage={stage} $isRTL={isRTL}>
                    <Theme.StageWrapper>
                        <Theme.FormTitle value={trans('auth.registration')} variant="h2" />
                        <Theme.SubtitleWrapper>
                            <Theme.SubtitleLine />
                            <Theme.FormSubtitle
                                value={trans('signup.formSubtitle1')}
                                variant="h5"
                            />
                        </Theme.SubtitleWrapper>
                        <Theme.InputController
                            type="text"
                            autoComplete="name"
                            {...{
                                control,
                                name: 'name',
                                label: trans('signup.academyName'),
                                placeholder: trans('signup.academyNamePlaceholder'),
                            }}
                        />

                        <Theme.InputController
                            type="text"
                            autoComplete="registrationNumber"
                            {...{
                                control,
                                name: 'registrationNumber',
                                label: trans('signup.commercialRegistrationNumber'),
                                placeholder: trans(
                                    'signup.commercialRegistrationNumberPlaceholder',
                                ),
                            }}
                        />
                        <Theme.InputController
                            type="text"
                            autoComplete="contactNumber"
                            {...{
                                control,
                                name: 'contactNumber',
                                label: trans('signup.contactNumber'),
                                placeholder: trans('signup.contactNumberPlaceholder'),
                            }}
                        />

                        <FormRow
                            title={trans('signup.academyOrClub')}
                            content={
                                <CheckBoxes
                                    control={control}
                                    name="organizationType"
                                    options={[
                                        {
                                            value: 'academy',
                                            label: trans('signup.academy'),
                                        },
                                        {
                                            value: 'club',
                                            label: trans('signup.club'),
                                        },
                                    ]}
                                    singleSelection
                                />
                            }
                        />

                        <FormRow
                            title={trans('signup.isMultiBranch')}
                            content={
                                <CheckBoxes
                                    control={control}
                                    name="isMultiBranch"
                                    options={[
                                        {
                                            value: 'true',
                                            label: trans('form.isMultiBranch.true'),
                                        },
                                        {
                                            value: 'false',
                                            label: trans('form.isMultiBranch.false'),
                                        },
                                    ]}
                                    singleSelection
                                />
                            }
                        />

                        <Theme.Button type="button" onClick={() => toggleStages()}>
                            {trans('signup.next')}
                        </Theme.Button>
                        <Theme.SentenceWrapper>
                            <Theme.SentenceText
                                variant="p"
                                value={trans('signup.alreadyHaveAccount')}
                            />
                            <Theme.SentenceLink href="sign_in" text={trans('signup.login')} />
                        </Theme.SentenceWrapper>
                    </Theme.StageWrapper>

                    <Theme.StageWrapper>
                        <Theme.BackButtonBody
                            $isRTL={isRTL}
                            type="button"
                            onClick={() => toggleStages()}
                        >
                            <Theme.BackButton
                                src={
                                    isRTL
                                        ? '/assets/icons/arrow-icon.png'
                                        : '/assets/icons/back-arrow-icon.png'
                                }
                                alt="back-arrow"
                            />
                        </Theme.BackButtonBody>
                        <Theme.FormTitle value={trans('auth.registration')} variant="h2" />

                        <Theme.SubtitleWrapper>
                            <Theme.SubtitleLine />
                            <Theme.FormSubtitle
                                value={trans('signup.formSubtitle2')}
                                variant="h5"
                            />
                        </Theme.SubtitleWrapper>

                        <Theme.InputController
                            type="text"
                            autoComplete="username"
                            {...{
                                control,
                                name: 'username',
                                label: trans('signup.adminName'),
                                placeholder: trans('signup.adminNamePlaceholder'),
                            }}
                        />
                        <Theme.InputController
                            type="text"
                            autoComplete="email"
                            {...{
                                control,
                                name: 'email',
                                label: trans('signup.adminEmail'),
                                placeholder: trans('signup.adminEmailPlaceholder'),
                            }}
                        />
                        <Theme.InputController
                            type="password"
                            autoComplete="password"
                            {...{
                                control,
                                name: 'password',
                                label: trans('signup.password'),
                                placeholder: trans('signup.passwordPlaceholder'),
                            }}
                        />
                        <Theme.Button
                            disabled={!isValid || isLoading}
                            isLoading={isLoading}
                            type="submit"
                        >
                            {trans('signup.submit')}
                        </Theme.Button>
                        {error?.message && <Theme.Errors>{error?.message}</Theme.Errors>}
                    </Theme.StageWrapper>
                </Theme.FormSlider>
            </Theme.FormSection>
        </Theme.Body>
    );
};

import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import api from '../../clients/wretchClient';

interface SignUpResponse {
    status: number;
    payload: {
        message: string;
        [key: string]: any;
    };
}

interface SignUpRequest {
    academyName: string;
    isMultiBranch: boolean;
    registrationNumber: string;
    contactNumber: string;
    adminName: string;
    email: string;
    password: string;
    organizationType: 'academy' | 'club';
}

const signUp = async (request: SignUpRequest): Promise<SignUpResponse> => {
    const response = await api.url('auth/register').post(request).json<SignUpResponse>();
    return response;
};

export const useSignUp = (
    options?: UseMutationOptions<SignUpResponse, Error, SignUpRequest, unknown>,
): UseMutationResult<SignUpResponse, Error, SignUpRequest, unknown> => {
    return useMutation<SignUpResponse, Error, SignUpRequest, unknown>({
        mutationFn: signUp,
        ...options,
    });
};
